@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'roboto';
}

p{
  line-height: 2rem
}

h2{
  letter-spacing: 0.1em;
}

#fruitvegimg{
  height: 30rem;
}

#healthycookingimg{
  height: 35rem;
}

.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.carousel-track {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-track > * {
  flex: 0 0 auto;
  margin: 0 2px;
}

.carousel-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 8px;
}

.nav-btn {
  background: #334659;
  color: white;
  padding: 5px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-btn:disabled {
  background: #334659;
  opacity: 0.3;
  cursor: not-allowed;
}

.carousel-indicators {
  display: flex;
  gap: 5px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}

.indicator.active {
  background: #334659;
}
